<template>
  <z-responsive-modal
    size="xl"
    :creating="creating"
    :hide-close="hideClose"
    @hide="$emit('hide')"
  >
    <div class="delivery-detail-modal-body">
      <div class="delivery-detail-header">
        <div
          class="d-flex flex-column flex-md-row justify-content-between align-items-start"
        >
          <div class="d-flex">
            <service-type-avatar
              :type="serviceType"
              size="lg"
              class="delivery-detail-avatar mr-3"
            />
            <div class="header-info">
              <p class="font-weight-bold header-title no-select">
                {{ title }}
              </p>
              <p class="text-lg header-subtitle no-select">
                {{ subtitle }}
              </p>
            </div>
          </div>

          <div
            class="header-buttons d-flex flex-column flex-md-row mt-4 mt-md-0 justify-content-end"
          >
            <z-button
              class="header-button mb-3 mb-md-0 w-100 d-block d-md-inline-block"
              variant="secondary"
              :disabled="creating"
              @click="$emit('cancel')"
            >
              Cancelar
            </z-button>
            <z-button
              class="header-button ml-0 ml-md-4 w-100 d-block d-md-inline-block"
              size="lg"
              :disabled="disableCreateButton"
              :loading="creating"
              @click="$emit('create')"
            >
              {{ createButtonText }}
            </z-button>
          </div>
        </div>
      </div>
      <slot></slot>
    </div>
  </z-responsive-modal>
</template>

<script>
import ZResponsiveModal from "@zubut/common/src/components/ZResponsiveModal";
import ServiceType from "@zubut/common/src/constants/services/type";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";

export default {
  name: "ZDeliveryCreationModal",

  components: {
    ServiceTypeAvatar,
    ZResponsiveModal
  },

  props: {
    enableCreate: {
      type: Boolean,
      default: false
    },
    creating: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      default: ""
    },
    serviceType: {
      type: Number,
      required: true,
      validator: () => [
        ServiceType.NUM_MULTI_POINT,
        ServiceType.NUM_PARCEL_DELIVERY
      ]
    },
    hideClose: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    createButtonText() {
      return this.serviceType === ServiceType.NUM_MULTI_POINT
        ? "Crear entrega"
        : "Crear guía";
    },
    disableCreateButton() {
      return this.enableCreate === false || this.creating === true;
    }
  }
};
</script>

<style scoped lang="scss">
.delivery-detail-modal-body {
  min-height: 94vh;
  padding: 1.5rem 1.5rem 3rem 1.5rem !important;
  transition: background-color 300ms ease;
}

.delivery-detail-header {
  .header-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  .header-subtitle {
    color: $comet;
    font-size: 12px;
    margin-top: 8px;
  }

  .header-buttons {
    width: 100%;
  }
}

@include media-breakpoint-up(md) {
  .delivery-detail-header {
    .header-button {
      width: auto !important;
    }

    .header-info > p {
      margin-bottom: 0;
      width: max-content;
    }
  }

  .delivery-detail-modal-body {
    padding: 2rem 2rem 3rem 2rem !important;
  }
}
</style>
