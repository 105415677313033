<template>
  <z-modal
    :show="show"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    centered
    hide-close
    size="md"
    body-class="p-5 rounded"
    @hide="$emit('update:show', false)"
  >
    <div>
      <p class="text-xl font-weight-bold">{{ type }} {{ verb }} con éxito</p>
      <p class="mt-2">
        Prepára el paquete, tu mensajero(s) se presentará en:
      </p>
      <strong>{{ destinationName }}</strong>
      <p v-if="isSchedluled">{{ date }}</p>
      <p>
        {{ destinationAddress }}
      </p>
      <slot name="info"></slot>
      <slot name="zip"></slot>
      <slot name="action">
        <z-button
          expanded
          variant="success"
          class="mt-4 p-2"
          @click="$emit('update:show', false)"
        >
          <z-icon name="Check" color="#fff" />
        </z-button>
      </slot>
    </div>
  </z-modal>
</template>

<script>
import ZModal from "@zubut/common/src/components/ZModal";
import { format } from "@zubut/common/src/utils/time";

export default {
  components: {
    ZModal
  },

  props: {
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    variant: {
      type: String,
      required: true,
      default: "express",
      validator: value =>
        ["express", "reservation", "parcel", "parcels"].includes(value)
    },
    destination: {
      type: Object,
      default: null
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isSchedluled() {
      return this.variant === "reservation" || this.destinationDate;
    },
    destinationDate() {
      return this.destination?.date;
    },
    date() {
      if (this.isSchedluled) {
        return format(
          this.destinationDate,
          "EEEE d 'de' MMM 'del' u, 'a las'  h:mm a"
        );
      } else {
        return "";
      }
    },
    type() {
      const variantDirectory = {
        express: "Entrega",
        parcel: "Guía",
        parcels: "Guías"
      };
      return variantDirectory[this.variant] || "Reservación";
    },
    verb() {
      return this.variant === "parcels" ? "creadas" : "creada";
    },
    destinationName() {
      return this.destination?.name || "Origen";
    },
    destinationAddress() {
      return this.destination?.address || "Dirección";
    }
  }
};
</script>
