<template>
  <div
    class="address-data-form  rounded"
    :class="variant === 'origin' ? 'origin' : 'destination border'"
  >
    <quick-message
      class="mb-3"
      with-icon
      :show.sync="hasMessage"
      title="Error"
      type="error"
      message="Por favor completa el formulario"
    />
    <div class="d-flex">
      <z-icon :name="iconName" size="20px" class="address-data-icon" />
      <div class="title font-weight-bold">
        {{ cardTitle }}
      </div>
      <a class="ml-auto edit-icon" @click="handleEdit()">
        <z-icon v-if="!editEnable" name="Edit"></z-icon>
      </a>
      <favorite-addresses
        v-if="editEnable && isOrigin"
        @selected="handleFavoriteAddressChange"
      />
    </div>
    <form v-if="editEnable">
      <div class="card-margin">
        <div class="subtitle">
          Datos de contacto
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <z-input
            v-model="newAddress.name"
            class="form-input"
            label="Nombre"
            :state="validName"
          />
          <z-input
            v-model="newAddress.phone"
            class="form-input"
            type="tel"
            label="Teléfono o celular"
            :state="validPhone"
          />
          <z-input
            v-model="newAddress.email"
            class="form-input"
            type="email"
            label="Correo Electronico"
            :state="validEmail"
          />
          <z-dropdown
            v-model="newAddress.schedule"
            :disabled="disableFormInput"
            :options="scheduleOptions"
            :show-header="false"
            rounded
            variant="white"
            max-height="200px"
            :name="scheduleDropdownName"
          />
        </div>
      </div>
      <div class="card-margin">
        <div class="subtitle">
          Dirección
        </div>
        <div class="d-flex flex-wrap justify-content-between">
          <z-input-address
            v-model="newAddress.addressText"
            class="form-input w-100"
            label="Calle, No. interior, No. exterior"
            required
            type="text"
            :state="validAddress"
            @selected="handleAutocompleteAddressChange"
          />
          <z-input
            v-model="newAddress.information"
            class="form-input w-100"
            label="Cruces, referencias o información del paquete"
          />
        </div>
        <div v-if="variant === 'origin'" class="d-flex align-items-center mt-3">
          <input
            id="sign-checkbox"
            v-model="newAddress.signature"
            :disabled="disableFormInput"
            type="checkbox"
          />
          <label class="checkbox-text font-weight-bold" for="sign-checkbox">
            Solicitar firma dígital del cliente/mensajero en las entregas
          </label>
        </div>
        <z-button variant="secondary" @click="handleCancel()">
          Cancelar
        </z-button>
        <z-button class="ml-2 mt-3" @click="handleSave()">
          Guardar Cambios
        </z-button>
      </div>
    </form>
    <div v-else class="subtitle-address card-margin">
      <div>
        {{ address.addressText }}
      </div>
      <div>
        {{ address.others }}
      </div>
      <div>
        {{ address.phone }}
      </div>
    </div>
  </div>
</template>

<script>
import FavoriteAddresses from "@/app/components/FavoriteAddresses.vue";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZInputAddress from "@zubut/common/src/components/ZInputAddress";
import {
  formatPhone,
  validateEmail,
  validateName
} from "@zubut/common/src/utils/strings";
import ParcelsDelivery from "@zubut/common/src/constants/parcels/schedule-delivery";
import ParcelsPickUp from "@zubut/common/src/constants/parcels/schedule-pick-up";
import * as mutation from "@/store/modules/parcel/mutations-types";
import _cloneDeep from "lodash/cloneDeep";

export default {
  name: "AddressDataForm",

  components: {
    FavoriteAddresses,
    ZDropdown,
    ZInputAddress
  },

  props: {
    deliveryMultiple: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "destination",
      validator: value => {
        return ["origin", "destination"].indexOf(value) !== -1;
      }
    }
  },

  data() {
    return {
      hasMessage: false,
      editing: true,
      address: {
        name: "",
        phone: "",
        email: "",
        address: "",
        others: "",
        signature: true,
        schedule: 0,
        valid: true
      },
      newAddress: {
        name: "",
        information: "",
        phone: "",
        email: "",
        address: "",
        addressText: "",
        others: "",
        signature: true,
        schedule: 0,
        valid: false
      },
      validName: false,
      validPhone: false,
      validEmail: null,
      pickUpSchedule: null
    };
  },

  computed: {
    disableFormInput() {
      return this.deliveryMultiple === true ? true : false;
    },
    iconName() {
      return this.isOrigin ? "StoreLocation" : "House";
    },

    cardTitle() {
      return this.isOrigin
        ? `Recolectar paquete  ${this.name ? "con " + this.name : "en"}`
        : `Entregar paquete ${this.name ? "a " + this.name : "en"}`;
    },

    name() {
      return this.address.name;
    },

    editEnable() {
      return this.editing;
    },

    isOrigin() {
      return this.variant === "origin";
    },

    scheduleDropdownName() {
      if (this.isOrigin) {
        return "Recolectar entre";
      }
      return "Entregar entre";
    },

    scheduleOptions() {
      if (this.isOrigin) {
        return ParcelsPickUp.options;
      }
      return ParcelsDelivery.options;
    },

    stateAddress() {
      const index = this.isOrigin ? 0 : 1;
      return this.$store.getters[this.buildParcelString("getAddress")](index);
    },

    stateAddressIsEmpty() {
      return this.stateAddress?.data?.address ? false : true;
    },

    defaultAddress() {
      return this.$store.getters[
        this.buildParcelString("getDefaultAddressParsed")
      ];
    },

    savedAddress() {
      if (this.isOrigin && this.stateAddressIsEmpty && this.defaultAddress) {
        return this.defaultAddress;
      }
      return this.stateAddress;
    },

    stateAdditionalOptions() {
      return this.$store.getters[
        this.buildParcelString("getAdditionalOptions")
      ];
    },

    validAddress() {
      return (
        this.newAddress?.address?.address?.length > 0 &&
        this.newAddress.valid === true
      );
    },

    isFormValid() {
      return (
        this.validName &&
        this.validPhone &&
        (this.validEmail == null || this.validEmail) &&
        this.validAddress
      );
    },
    isDeliveryFormValid() {
      return this.$store.getters["parcel/multiple/isFormValid"];
    }
  },

  watch: {
    "newAddress.name": {
      handler(newVal) {
        if (validateName(newVal)) {
          this.validName = true;
        } else this.validName = false;
      }
    },

    "newAddress.email": {
      handler(newVal) {
        if (newVal.length > 0) {
          if (validateEmail(newVal)) {
            this.validEmail = true;
          } else {
            this.validEmail = false;
          }
        } else {
          this.validEmail = null;
        }
      }
    },

    "newAddress.phone": {
      handler(newVal, oldVal) {
        this.validatePhone(newVal, oldVal);
      }
    },

    "newAddress.addressText": {
      handler() {
        this.newAddress.valid = false;
      }
    }
  },

  created() {
    this.setStateAddress();
  },
  mounted() {
    if (this.deliveryMultiple && this.isDeliveryFormValid === true)
      this.editing = false;
  },

  methods: {
    buildParcelString(string) {
      const parcelString = this.deliveryMultiple
        ? `parcel/multiple/${string}`
        : `parcel/${string}`;
      return parcelString;
    },
    setStateAddress() {
      if (this.isOrigin && this.stateAddressIsEmpty && this.defaultAddress) {
        this.setDefaultAddress();
      }

      const {
        id,
        address,
        information,
        name,
        phone,
        email,
        schedule
      } = this.savedAddress.data;
      this.address.id = id;
      this.address.address = { ...address };
      this.address.addressText = address.address;
      this.address.name = name;
      this.address.email = email;
      this.address.phone = formatPhone(phone);
      this.address.information = information;
      this.address.position = address.position ? { ...address.position } : null;
      this.address.schedule =
        this.deliveryMultiple === true
          ? ParcelsPickUp.NUM_PICK_UP_SCHEDULE_MIDDAY
          : schedule === undefined
          ? ParcelsPickUp.NUM_PICK_UP_SCHEDULE_MORNING
          : schedule;

      if (this.isOrigin) {
        this.address.signature =
          this.deliveryMultiple === true
            ? this.stateAdditionalOptions.deliveryProof
            : this.stateAdditionalOptions.signature;
      }
      this.newAddress = _cloneDeep(this.address);
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    setDefaultAddress() {
      this.$store.commit(
        this.buildParcelString(mutation.UPDATE_DESTINATION),
        this.defaultAddress
      );
    },

    handleEdit() {
      this.editing = true;
      this.newAddress = _cloneDeep(this.address);
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    handleFavoriteAddressChange(address) {
      this.newAddress.address = {
        address: address.address,
        position: address.position
      };
      this.newAddress.addressText = address.address;
      this.newAddress.name = address.name;
      this.newAddress.phone = formatPhone(address.phone);
      this.newAddress.information = address.information;
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    handleAutocompleteAddressChange(address) {
      this.newAddress.address = {
        address: address.address,
        position: address.position
      };
      this.newAddress.addressText = address.address;
      this.newAddress.position = address.position;
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    handleCancel() {
      this.editing = false;
      this.hasMessage = false;
      this.$nextTick(() => {
        this.newAddress.valid = true;
      });
    },

    handleSave() {
      if (!this.isFormValid) {
        this.hasMessage = true;
      } else {
        this.hasMessage = false;
        const index = this.isOrigin ? 0 : 1;
        const formattedDestination = {
          index,
          data: {
            address: this.newAddress.address,
            name: this.newAddress.name,
            email: this.newAddress.email,
            phone: this.newAddress.phone,
            information: this.newAddress.information,
            schedule: this.newAddress.schedule
          }
        };
        this.address = { ...this.newAddress };
        this.$store.commit(
          this.buildParcelString(mutation.UPDATE_DESTINATION),
          formattedDestination
        );

        this.editing = false;

        if (this.isOrigin) {
          this.$store.commit(
            this.buildParcelString(mutation.SET_ADDITIONAL_OPTIONS_SIGNATURE),
            this.newAddress.signature
          );
        }
      }
    },

    validatePhone(phone, prevphone) {
      if (this.newAddress.phone.length > 14) {
        this.newAddress.phone = prevphone;
      }
      if (this.newAddress.phone.length === 14) {
        this.validPhone = true;
      } else this.validPhone = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.address-data-form {
  padding: 20px 20px 20px 12px;

  &.origin {
    background-color: $solitude-2;
  }

  .address-data-icon {
    margin-right: 12px;
  }

  .title {
    font-size: 14px;
  }

  .edit-icon {
    cursor: pointer;
  }

  .subtitle {
    padding-top: 20px;
    font-size: 12px;
    color: $comet;
  }

  .subtitle-address {
    font-size: 12px;
    color: black;
    margin-top: 8px;
  }

  .checkbox-text {
    font-size: 12px;
    padding-left: 8px;
    margin: 0;
    margin-top: 2px;
  }

  .card-margin {
    margin-left: 32px;
  }

  .form-input,
  .z-dropdown {
    width: 100%;
    margin: 6px 0;
  }

  @include media-breakpoint-up(md) {
    .form-input,
    .z-dropdown {
      width: 49%;
    }
  }

  .custom-select {
    border-radius: 6px;
    box-shadow: none;
    padding-top: 4.75px;
    padding-bottom: 4.75px;
    font-size: 12px;
    padding-left: 16px;
  }

  .is-invalid {
    border-radius: 6px;
    background: white;
  }

  .is-valid {
    border: 1px solid #d4d7df;
  }
}
</style>
