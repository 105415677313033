const PICK_UP_SCHEDULE_MORNING = "9 a 11 horas";
const PICK_UP_SCHEDULE_MIDDAY = "11 a 14 horas";

const NUM_PICK_UP_SCHEDULE_MORNING = 0;
const NUM_PICK_UP_SCHEDULE_MIDDAY = 1;

const get = {};
get[NUM_PICK_UP_SCHEDULE_MORNING] = PICK_UP_SCHEDULE_MORNING;
get[NUM_PICK_UP_SCHEDULE_MIDDAY] = PICK_UP_SCHEDULE_MIDDAY;

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options,
  NUM_PICK_UP_SCHEDULE_MORNING,
  NUM_PICK_UP_SCHEDULE_MIDDAY
};
