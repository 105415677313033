<template>
  <div class="d-flex">
    <div v-for="item in infoItems" :key="item.title" class="w-50">
      <div class="font-weight-bold ">{{ item.title }}</div>
      {{ item.data }}
    </div>
  </div>
</template>

<script>
export default {
  name: "PackagePaymentInfo",
  props: {
    infoItems: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped></style>
