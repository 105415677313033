<template>
  <b-dropdown
    v-if="addresses.length"
    :menu-class="[
      'z-favorite-addresses',
      { 'z-favorite-addresses-icon': type === 'icon' },
      { 'z-favorite-addresses-button': type === 'button' }
    ]"
    variant="link"
    size="md"
    no-caret
    lazy
    toggle-class="p-0 z-favorite-addresses-toggle"
    @shown="active = true"
    @hidden="active = false"
  >
    <template v-slot:button-content>
      <z-icon
        v-if="type === 'icon'"
        name="FavoriteAddress"
        :variant="active ? 'active' : 'normal'"
        v-tooltip.top="'Direcciones favoritas'"
        size="20"
        hover
      />
      <z-button v-else variant="primary-alt" size="sm">
        <div class="d-flex align-items-center">
          <z-icon
            name="FavoriteAddress"
            variant="active"
            class="mr-2"
            size="22"
          />
          Agregar desde favoritos
        </div>
      </z-button>
    </template>
    <b-dropdown-item-button
      v-for="favAddress in addresses"
      :key="`address-${favAddress.id}`"
      @click="handleSelectedAddress(favAddress)"
    >
      <div class="d-flex">
        <div>
          <z-icon
            name="FavoriteSaved"
            variant="active"
            class="mx-3"
            size="16"
          />
        </div>
        <div class="address-wrapper">
          <div class="text-sm font-weight-bold">
            {{ favAddress.name }}
          </div>
          <div class="text-sm">{{ favAddress.address }}</div>
        </div>
      </div>
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
import ZButton from "./ZButton";
import ZIcon from "./ZIcon";

export default {
  name: "ZFavoriteAdresses",

  components: {
    ZButton,
    ZIcon
  },

  props: {
    addresses: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: "icon",
      validator: val => ["icon", "button"].indexOf(val) > -1
    }
  },

  data() {
    return {
      active: false
    };
  },

  methods: {
    handleSelectedAddress(address) {
      this.$emit("selected", address);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

::v-deep .z-favorite-addresses {
  position: relative;
  border-radius: 10px;
  border: 0px;
  box-shadow: 0 3px 9px 1px rgba(29, 34, 63, 0.2);
  padding: 0.5rem;

  .address-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dropdown-item {
    padding-left: 0rem;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: $pattens-blue;
  }

  .address-name {
    font-size: 12px;
    font-weight: 500;
  }
}

@include media-breakpoint-down(md) {
  ::v-deep .z-favorite-addresses-icon {
    top: 30px !important;
    left: calc(-80vw + 22px) !important;
    width: 80vw;
    transform: none !important;
  }

  ::v-deep .z-favorite-addresses-button {
    width: 90vw;
  }
}
</style>

<style lang="scss">
.z-favorite-addresses-toggle:hover,
.z-favorite-addresses-toggle:focus {
  text-decoration: none !important;
}
</style>
