<template>
  <div
    class="delivery-mode rounded border d-flex align-items-center justify-content-between"
  >
    <div class="font-weight-bold delivery-type-text w-100">
      Selecciona tu tipo de Servicio
    </div>
    <z-dropdown
      v-model="deliverySelected"
      class="delivery-mode-input"
      type="webapp"
      :show-header="false"
      :options="options"
    />
  </div>
</template>

<script>
import ZDropdown from "@zubut/common/src/components/ZDropdown";

import Parcels from "@/services/parcels";
import { diffInDays } from "@zubut/common/src/utils/time";
import parseISO from "date-fns/parseISO";
import * as mutation from "@/store/modules/parcel/mutations-types";
import DeliveryMode from "@zubut/common/src/constants/parcels/deliveryMode";
export default {
  name: "DeliveryMode",
  components: {
    ZDropdown
  },
  props: {
    deliveryMultiple: { type: Boolean, default: false }
  },
  data() {
    return {
      options: [
        {
          text: `${DeliveryMode.SAME_DAY} (Antes de las 11:00 am)`,
          value: DeliveryMode.NUM_SAME_DAY,
          disabled: this.getEarlistDeliveryDate()
        },
        {
          text: DeliveryMode.NEXT_DAY,
          value: DeliveryMode.NUM_NEXT_DAY,
          disabled: false
        }
      ]
    };
  },
  computed: {
    deliverySelected: {
      get() {
        return this.$store.getters[this.getterName];
      },
      set(value) {
        this.$store.commit(this.commitName, value);
      }
    },
    commitName() {
      const commitName = this.deliveryMultiple
        ? `parcel/multiple/${mutation.UPDATE_DELIVERY_MODE}`
        : `parcel/${mutation.UPDATE_DELIVERY_MODE}`;

      return commitName;
    },
    getterName() {
      const getterName = this.deliveryMultiple
        ? "parcel/multiple/getDeliveryMode"
        : "parcel/getDeliveryMode";

      return getterName;
    }
  },

  methods: {
    getEarlistDeliveryDate() {
      Parcels.earliestDeliveryDate({ deliveryMode: DeliveryMode.NUM_SAME_DAY })
        .then(res => {
          const today = new Date();
          if (diffInDays(today, parseISO(res.earliestDeliveryDate)) !== 0) {
            this.options[0].disabled = true;
            this.deliverySelected = DeliveryMode.NUM_NEXT_DAY;
            return true;
          } else return false;
        })
        .catch(err => {
          this.$captureError(err);
          return false;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.delivery-mode {
  padding: 11px;
  .delivery-type-text {
    font-size: 12px;
    padding-left: 10px;
  }
}

@include media-breakpoint-up(lg) {
  .delivery-mode-input {
    min-width: 253px;
  }
}

@include media-breakpoint-down(lg) {
  .delivery-mode {
    flex-direction: column;
  }

  .delivery-mode-input {
    margin: 12px;
    width: 100%;
  }
}
</style>
