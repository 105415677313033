<template>
  <package-card :content="content">
    <info-link-button />
  </package-card>
</template>

<script>
import InfoLinkButton from "./InfoLinkButton";
import PackageCard from "./PackageCard";
export default {
  name: "PackageInfo",
  components: { InfoLinkButton, PackageCard },
  props: { content: { type: Object, required: true } }
};
</script>

<style lang="scss" scoped></style>
