<template>
  <div role="button" class="info d-inline-flex align-items-center">
    <z-icon name="Info" variant="active" size="16" />
    <div class="link">
      Cómo preparar un paquete
    </div>
  </div>
</template>

<script>
export default {
  name: "InfoLinkButton"
};
</script>

<style lang="scss" scoped>
.info {
  margin-top: 20px;
  padding: 9.8px 0;
}
.link {
  color: var(--primary);
  font-size: 12px;
  padding-left: 6px;
}
</style>
