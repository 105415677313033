<template>
  <div class="package-payment-method rounded border">
    <slot></slot>
    <hr class="m-0 mt-3 mb-3" />
    <div class="d-flex justify-content-between mb-3">
      <div class="font-weight-bold ">Forma de pago</div>
      <div class="d-flex align-items-center">
        <z-icon class="mr-1" name="Info" size="16" />
        <div class="subtitle">Solo aceptamos pago con wallet</div>
      </div>
    </div>
    <wallet-balance size="sm" />
  </div>
</template>

<script>
import WalletBalance from "@/app/components/WalletBalance";

export default {
  name: "PackagePaymentMethod",

  components: {
    WalletBalance
  }
};
</script>

<style scoped lang="scss">
.package-payment-method {
  font-size: 12px;
  padding: 21px;

  .subtitle {
    color: $comet;
  }
}
</style>
