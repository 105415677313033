const DELIVERY_SCHEDULE_AFTERNOON = "15 a 17 horas";
const DELIVERY_SCHEDULE_EVENING = "17 a 19 horas";

const NUM_DELIVERY_SCHEDULE_AFTERNOON = 0;
const NUM_DELIVERY_SCHEDULE_EVENING = 1;

const get = {};
get[NUM_DELIVERY_SCHEDULE_AFTERNOON] = DELIVERY_SCHEDULE_AFTERNOON;
get[NUM_DELIVERY_SCHEDULE_EVENING] = DELIVERY_SCHEDULE_EVENING;

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options
};
