<template>
  <div class="label rounded w-100">
    <div>
      <z-icon :name="content.iconName" view-box="0 0 32 32" size="32" />
      <div class="title">
        {{ content.title }}
      </div>

      <div
        v-for="(paragraph, index) in content.paragraphs"
        :key="index"
        class="text"
      >
        {{ paragraph }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PackageCard",
  props: {
    content: { type: Object, required: true }
  }
};
</script>

<style lang="scss" scoped>
.label {
  background-color: var(--primary-bg);
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-weight: 600;
  margin-bottom: 8px;
}
.text {
  font-size: 12px;
  color: black;
  margin-bottom: 16px;
}
</style>
