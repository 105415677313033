<template>
  <create-service-success-modal
    :show="show"
    :variant="variant"
    :destination="address"
    :no-close-on-backdrop="noCloseOnBackdrop"
    :no-close-on-esc="noCloseOnEsc"
    @update:show="$emit('update:show', $event)"
  >
    <template v-slot:info>
      <div class="label-info rounded">
        <div class="d-flex">
          <z-icon name="Info" class="mr-2" />
          <div class="">
            Puedes volver a descargar la etiqueta desde
            <strong class="font-weight-bold">Rastreo de entregas</strong> o en
            <strong class="font-weight-bold">Detalle de las entrega.</strong>
          </div>
        </div>
        <img
          src="@/assets/img/img-download-shipping-label.png"
          alt="shipping label info"
          class="ml-2 pr-3 mt-3 w-100"
        />
      </div>
    </template>
    <template v-slot:zip>
      <slot></slot>
    </template>
    <template v-slot:action>
      <z-button
        expanded
        variant="primary"
        class="mt-4 p-3"
        :loading="loading"
        :disabled="loading"
        @click="$emit('get-shipping-label')"
      >
        Descargar {{ subject }}
      </z-button>
    </template>
  </create-service-success-modal>
</template>

<script>
import CreateServiceSuccessModal from "@/app/components/CreateServiceSuccessModal";

export default {
  name: "PackageConfirmationModal",

  components: {
    CreateServiceSuccessModal
  },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    address: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: "parcel"
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: false
    },
    noCloseOnEsc: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    subject() {
      return this.variant === "parcels" ? "etiquetas" : "etiqueta";
    }
  }
};
</script>

<style lang="scss" scoped>
.label-info {
  background-color: $solitude-2;
  padding: 20px;
  font-size: 12px;
}
</style>
