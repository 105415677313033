<template>
  <z-favorite-addresses
    :addresses="addresses"
    :type="type"
    @selected="$emit('selected', $event)"
  />
</template>

<script>
import ZFavoriteAddresses from "@zubut/common/src/components/ZFavoriteAddresses";

export default {
  name: "FavoriteAddresses",

  components: {
    ZFavoriteAddresses
  },

  props: {
    type: {
      type: String,
      default: "icon",
      validator: val => ["icon", "button"].indexOf(val) > -1
    },
    addresses: {
      type: Array,
      default: () => []
    }
  }
};
</script>
